/**
 *  * Only use ES5 in this file since it's also read in gatsby-node.js,
 *   * which is run by node.js, which currently does not support ES6...
 *    **/

const foodservicePath = `/sortiment/foodservice`

const foodserviceTrademarkPath = (trademarkId) =>
  `${foodservicePath}/${trademarkId}`

const foodserviceProductPath = (trademarkId, productId) =>
  `${foodserviceTrademarkPath(trademarkId)}/${productId}`

const productPath = (trademarkId, productId) =>
  `${foodserviceTrademarkPath(trademarkId)}/${productId}`

module.exports.foodservicePath = foodservicePath
module.exports.foodserviceTrademarkPath = foodserviceTrademarkPath
module.exports.foodserviceProductPath = foodserviceProductPath
module.exports.productPath = productPath
